@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base{
    body {
        @apply font-[Raleway];
    }

    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

.banner {
    background: url(./assets//banner.jpg) no-repeat right center;
    background-size: cover;
}